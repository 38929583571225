<template>
  <div class="main">
       <TheTopbar/>
    <slot />
    <div >
       
        <div class="main-wrapper">
          <div class="d-flex justify-content-between modal-header m-3"> 
                <h5 class="text-white">PENDING SIGNUPS</h5>
               
            </div>
        
             <div class="">
                     <div class="" >
                          <div class="table-responsive">
                        
                            <table class="table">
                            <thead>
                                <tr >
                                    <th>Login ID</th>
                                    <th>Signup Date</th>
                                    <th>Reminder Date</th>
                                    <th>Activation Key</th>
                                    <th>Signup Host</th>
                                    <th  v-b-tooltip.hover title="Click to activate customer's account">Activate</th>
                                    <th v-b-tooltip.hover title="Click to delete the inactive account">Delete</th>
                                    <th></th>
                                   
                                </tr>
                                </thead>
                              
                                <tbody v-for="item in pending" :key="item.id">
                                     <td v-if="item.loginIdType=='EMAIL'">{{item.loginId}}</td>
                                    <td v-if="item.loginIdType=='PHONE'">{{item.loginId}}</td>
                                    <td>{{formatDate(item.signupDate)}}</td>
                                    <td v-if="item.followUpDate != null">{{formatDate(item.followUpDate)}}</td>
                                    <td v-else></td>
                                    <td>{{item.activationKey}}</td>
                                    <td>{{item.signupHost}}</td>
                                    <!-- <td > <button class="btn btn-info text-dark" @click="resendOtp(item.companyId, item.loginId)" v-b-tooltip.hover title="Click to resend OTP">Resend OTP</button></td>
                                    <td > <button class="btn btn-primary text-dark" @click="sendReminder(item.companyId, item.loginId)">Send Reminder</button></td> -->
                                    <td > <button class="btn btn-dark text-dark" @click="activateAccount(item.companyId, item.loginId)">Activate</button></td> 
                                    <td > <button class="btn btn-dark text-dark" @click="deleteAccount(item.companyId, item.loginId)" >Delete</button></td> 
                                    
                                </tbody>
                            </table>
                              <table v-if="pending.length==0" class="table-responsive">
                                    <th class="text-center">No Customers Found</th>
                                    
                            </table>
                    
                    </div> 
                      </div>
                      
               
                </div>             
    
        </div>
       
	
    </div>
    
        
    
   
  </div>
</template>
<script>
import TheTopbar from '../components/TheTopbar.vue'
import moment from 'moment'




export default {
    name: 'PendingSignup',
    components: {
        TheTopbar
          
    },
      data: function(){
          return{
             pending:[],
             searchCustomer:"",
             numberOfDays: '31',
             companyId:"",
             loginId:"",
             
          }
    },
   
    computed:{
       
        init() {
          return this.$store.state.init;
        },
        show() {
          return this.$store.state.show;
        },
      

    },
  
    
    methods:{
         formatDate: function(date) {
          return moment(new Date(date)).format('MM/DD/YYYY hh:mm:ss A');
        },
        gotoCustomer(id){
            this.$router.push({name: 'CustomerProfile', params:{entityId:id}})
        },
        
         getPendingSignup(){
             const params = {
		          day: this.numberOfDays,
		        }

		        const data = Object.keys(params)
		          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
		          .join('&');
		   
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/getPendingSignups',
                    data
                    };

				this.axios(options)
				.then((response) => { 
                   console.log(response.data)
                   this.pending = response.data
                })
            },
             

        
      checkSession: function() {

				const options = {
				method: 'POST',
				headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
				url: '/ax/sessionCheck',
				};

				this.axios(options)
				.then((response) => { 
                    console.log(response.data)
                  
					}).catch((err) => {
						
					if(err.response.data.msgCode == '00001') {
						window.location.href = "/#/login";
					}  

					if(err.response.data.msgCode == '00020') {
						
						window.location.href = "/#/setup-profile";
                   
						
					}  

					})
			},
            deleteAccount(companyId, loginId){
                this.companyId = companyId;
                this.loginId = loginId;

                 const params = {
		          companyId: companyId,
                  loginId: loginId,
		        }

		        const data = Object.keys(params)
		          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
		          .join('&');
		   
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/deletePendingSignup',
                    data
                    };

				this.axios(options)
				.then((response) => { 
                   console.log(response.data)
                   this.getPendingSignup();
                })
            },

            resendOtp(companyId, loginId){
                this.companyId = companyId;
                this.loginId = loginId;

                 const params = {
		          companyId: companyId,
                  loginId: loginId,
		        }

		        const data = Object.keys(params)
		          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
		          .join('&');
		   
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/resendActivationKey',
                    data
                    };

				this.axios(options)
				.then((response) => { 
                   console.log(response.data)
                   this.getPendingSignup();
                })
            },
            activateAccount(companyId, loginId){
                this.companyId = companyId;
                this.loginId = loginId;

                 const params = {
		          companyId: companyId,
                  loginId: loginId,
		        }

		        const data = Object.keys(params)
		          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
		          .join('&');
		   
                const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/activateSignup',
                    data
                    };

				this.axios(options)
				.then((response) => { 
                   console.log(response.data)
                   this.getPendingSignup();
                })
            }
             

          
    },
    beforeMount(){
         this.checkSession();
        
        
    },
    mounted(){
        this.getPendingSignup();
     
      
            
        
        
    }
   
     


        
}
</script>

<style lang="scss" scoped>


.single-slide{
    border: 1px solid white;
    border-radius: 20px;
    font-size: 1rem;
    cursor: pointer;
}

  
h6{
    padding-top: 10px;
    color: black;
}
.spinner {
		width: 50px !important;
		margin: 0 !important;
	}
.main-content {
		display: flex; 
	    align-items: center;
	    justify-content: space-between !important;
	    width: 100%;
	}
.left-content {
		display: block;
	    align-items: center;
	    justify-content: center;
        justify-content: space-evenly;
        width: 100% !important;
}
.left-content .graph-wrappper {
	    display: flex; 
		align-items: center;
		justify-content: space-between;
}

.left-content .graph-wrappper .box { 
		width: 100%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

#line-chart{
	  width: 300px !important;
	  height: 400px !important;
	}

.main-container{
		padding: 1px 16px;
		height: auto;
    width: auto !important;
	}
.navbar{
		display: flex;
		width: auto;
        justify-content: flex-start;

	}
ul{
    list-style: none !important;
}
.navbar li a {
		display: block;
		color: black;
		padding: 8px 16px;
		text-decoration: none;
		}

.navbar li a.active {
		color: #A809CA;
		}

.navbar li a:hover:not(.active) {
		color: #A809CA !important;
		}

.main-wrapper{
    width: 100% !important;
    
}






.chart-card{
    margin: 20px 0px;
    width: auto;
    height: 200px;
    background: transparent;
    border-radius: 6px;
    background: O rgba(255,255,255,0.2);
  backdrop-filter:blur(20px);
  box-shadow:0 0 30px rgba(0,0,0,0.3);
   border: 2px solid white;
}
.right-content {
	display: block; 
	align-items: center;
	justify-content: space-evenly;
    width: auto !important;
}
.right-content .graph-wrappper {
	display: flex; 
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.right-content .graph-wrappper  .box { 
	width: 100%;
	justify-content: center;
	align-items: center;
    display: inline-flex;
}
		
.right-content .graph-wrappper .box .soc-card{
    margin: 20px 5px;
    width: 100px;
    height: 200px;
    background: transparent;
    border-radius: 6px;
     background: O rgba(255,255,255,0.2);
  backdrop-filter:blur(20px);
  box-shadow:0 0 30px rgba(0,0,0,0.3);
   border: 2px solid white;
}

.soc-card img{
    display: block;
    width: 3rem;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    padding-top: 10px;
}
.soc-card .stat{
    display: block;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    color: black;
    font-size: 1rem;
}

.table {
    padding: 10px;
    border-collapse: collapse;
    width: 100% !important;
    margin: 0 10px;
    background: O rgba(255,255,255,0.2);
  backdrop-filter:blur(20px);
  box-shadow:0 0 30px rgba(0,0,0,0.3);
   border: 2px solid white;
    color: black;
    text-align: center !important;
    font-size: 80%;
    border-radius: 10px;
    padding: 0.5em 2em;
    overflow-y: scroll;
    max-height: 700px; 
    z-index: 9999 !important;
    .btn-main{
	  background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
		background-size:0% 100%;
		background-repeat:no-repeat;
		transition:.4s;
        font-size: 0.75rem;
		width:auto;
		border-radius: 10px;
		padding:2px;
        width: 100px;
		border: 1px #007bff solid;
		color:black !important;
  }
  .btn-main:hover{
	  background-size:100% 100%;
    	color:black !important;
  }
}
th, td {
  padding: 0.5em;
  z-index: 9999 !important;
}
tr{
  width: 100%;
  padding: 0.5em 2em;
}
.table thead{
  background: #8b8b8b50 !important;
  width: 100% !important;
  color: black;
  font-size: 0.75rem;
  letter-spacing: 2%;
  border-radius: 10px;
  z-index: 9999 !important;
}
.inside-table{
    text-align: center !important;
    border-collapse: collapse;
    width: auto !important;
    margin: 0 10px;
    background: #8b8b8b50 !important;
    display: flex;
    justify-content: flex-start !important;
    padding: 0 !important
}

.inside-table img{
    width: 2rem;
    
  }

  .subtitle{
    display: flex;
    justify-content: space-between; 
}
.subtitle h5:hover{
  color: #A809CA;
  cursor: pointer;
}
.customer-card{
    display: flex;
    width: auto !important;
    justify-content: space-evenly;
}
* {
  box-sizing: border-box;
}

a, a:hover {
  text-decoration: none;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    height: auto;
    padding-top: 100px;
  }

}

.column {
  float: left;
  width: 20%;
  padding: 0 10px;
   
  
}

.row{
    background-image: linear-gradient(#D12B31, #F54AAB);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
}

.row {margin: 0 -5px;}
.row:after {
  content: "";
  display: table;
  clear: both;
}
.card {
    padding: 16px;
    text-align: center;
    background-color: transparent !important;
    color:white;
    border: none !important;
    border-right: 1px solid white !important;
    display: flex;
}
button{
    color: white;
    background-color: transparent !important;
    font-size: 1rem;

}
.tablinks{
    border: none !important;
    background-color: transparent !important;

}
@media screen and (max-width: 768px) {
    .soc-card .stat{
    
    font-size: 0.75rem;
}
    .avatar{
         width: 12rem;
        }
    .navbar li{
            font-size: 15px;
        }
    .main-content {
		display: block; 
	    
	}
    .right-content .graph-wrappper {
        display: block; 
        overflow: auto; 
        
    }
        .column{
            width: 100%;
            display: block;
            margin-bottom: 20px;
        }
       .wrapper {
       overflow: auto; 
       }


}


</style>